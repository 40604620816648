import React from "react"

import { CountUp } from "countup.js"

import CarneIcon from "../images/carne.png"
import CartIcon from "../images/cos-cumparaturi.png"
import DocumentIcon from "../images/document.png"
import GrupIcon from "../images/grup.png"
import LegumeIcon from "../images/legume.png"
import SmileyIcon from "../images/smiley-face.png"
import Angajati from "../images/angajati-penny.png"
import Cifra from "../images/cifra-afaceri.png"
import LedIcon from "../images/led.png"
import Magazine from "../images/magazine.png"
import Voluntari from "../images/voluntari.png"
import Pig from "../images/pig.png"

import ArrowLeft from "../images/arrow-left.svg"
import ArrowRight from "../images/arrow-right.svg"

class NumbersAnagajati extends React.Component {
  componentDidMount() {
    const self = this

    $("#numbers-carousel")
      .slick({
        slidesToShow: 3,
        prevArrow: ".numbers-arrow-left",
        nextArrow: ".numbers-arrow-right",
        infinite: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      })
      .on("afterChange", function (event, slick, currentSlide, nextSlide) {
        self.runNumbers()
      })

    this.runNumbers()
  }

  // eslint-disable-next-line class-methods-use-this
  runNumbers() {
    $(".counter").each(function () {
      const item = $(this)
      const end = item.attr("data-end")
      const start = item.attr("data-start")

      const count = new CountUp(this.id, end, { startVal: start, useGrouping: true, decimal: ',', separator: '.' })
      count.start()
    })
  }

  render() {
    return (
      <div className="penny-numbers-container mt-5">
        <h1 className="text-success text-center akko-bold py-3">
          PENNY în cifre
        </h1>
        <div className="bg-success">
          <div className="container position-relative py-5">
            <img
              className="numbers-arrow numbers-arrow-left"
              src={ArrowLeft}
              alt=""
            />
            <div id="numbers-carousel">
              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={Angajati} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-magazine"
                      className="counter"
                      data-end="7285"
                      data-start="0"
                    >
                      7285
                    </span>
                  </h2>
                  <p className="text-white">angajați activi la final de an</p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={DocumentIcon} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-produser"
                      className="counter"
                      data-end="160000 "
                      data-start="0"
                    >
                      160.000
                    </span>
                  </h2>
                  <p className="text-white">
                    ore de instruire
                  </p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={Angajati} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-produse"
                      className="counter"
                      data-end="90"
                      data-start="0"
                    >
                      90
                    </span>
                    %
                  </h2>
                  <p className="text-white">
                    rata de ocupare din intern a rolurilor de management
                  </p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={Angajati} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span>
                      302
                    </span>
                  </h2>
                  <p className="text-white">
                    femei în poziții de management
                  </p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={Cifra} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-instruire"
                      className="counter"
                      data-end="2300000"
                      data-start="0"
                    >
                      2.3
                    </span>{" "}
                    Euro
                  </h2>
                  <p className="text-white">
                    investiți în comunitate
                  </p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={Angajati} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-angajati"
                      className="counter"
                      data-end="1160"
                      data-start="0"
                    >
                      1160
                    </span>
                  </h2>
                  <p className="text-white">
                    de ore alocate de angajați pentru acțiuni de voluntariat sau ajutor pentru comunitate
                  </p>
                </div>
              </div>

            </div>

            <img
              className="numbers-arrow numbers-arrow-right"
              src={ArrowRight}
              alt=""
            />
          </div>
        </div>
      </div>
    )
  }
}

export default NumbersAnagajati
